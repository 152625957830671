.darkModePermissions .dx-treelist-container {
  background-color: #1e1e1e !important;
  color: #fff !important;
}

.darkModePermissions .dx-treelist-container .dx-checkbox-icon {
  border: 2px solid white;
}

.darkModePermissions .dx-checkbox-checked .dx-checkbox-icon {
  border: none !important;
}

.darkModePermissions .dx-treelist-container .dx-checkbox-indeterminate {
  display: none !important;
}

.darkModePermissions .dx-treelist-container .dx-treelist-headers,
.darkModePermissions .dx-treelist-container .dx-treelist-headers {
  color: #fff !important;
  background-color: #1e1e1e !important;
}

.darkModePermissions .dx-treelist-container .dx-treelist-rowsview {
  color: #fff !important;
  background-color: #1e1e1e !important;
}

.darkModePermissions .dx-treelist-container .dx-treelist-headers .dx-column-indicators .dx-sort {
  color: #fff !important;
}

.darkModePermissions
  .dx-treelist-container
  .dx-treelist-headers
  .dx-treelist-table
  .dx-row
  > td:hover
  .dx-treelist-text-content {
  color: #fff !important;
}

.darkModePermissions .dx-treelist-container .dx-sort-indicator,
.dx-treelist-headers .dx-treelist-table .dx-row > td:hover .dx-treelist-text-content {
  color: #fff !important;
}

.darkModePermissions .dx-selection > * {
  color: #fff !important;
}
