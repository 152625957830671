.rst__vigoPlaceholder {
    position: relative;
    height: 200px;
    max-width: 300px;
    padding: 10px;
    background-color: #f4f5fa;
    border-radius: 10px;
    text-align: center;
  }
  .rst__vigoPlaceholder,
  .rst__vigoPlaceholder > * {
    box-sizing: border-box;
  }
  .rst__vigoPlaceholder::before {
    border: 3px dashed #f88a8a;
    content: '';
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    z-index: -1;
  }
  
  /**
   * The outline of where the element will go if dropped, displayed while dragging
   */
  .rst__vigoPlaceholder_LandingPad,
  .rst__vigoPlaceholder_CancelPad {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
  .rst__vigoPlaceholder_LandingPad *,
  .rst__vigoPlaceholder_CancelPad * {
    opacity: 0.9 !important;
    z-index: 1;
  }
  .rst__vigoPlaceholder_LandingPad::before,
  .rst__vigoPlaceholder_CancelPad::before {
    background-color: rgb(155, 202, 224);
    border-color: white;
    z-index: 1;
  }
  
  /**
   * Alternate appearance of the landing pad when the dragged location is invalid
   */
  .rst__vigoPlaceholder_CancelPad::before {
    background-color: #e6a8ad;
  }