.darkConsignmentMap .leaflet-layer,
.darkConsignmentMap .leaflet-control-zoom-in,
.darkConsignmentMap .leaflet-control-zoom-out,
.darkConsignmentMap .leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}

.darkConsignmentMap .leaflet-container {
  background: #000;
}

.darkConsignmentMap .leaflet-popup-content-wrapper,
.darkConsignmentMap .leaflet-popup-tip {
  background-color: #121212 !important;
  border: 1px solid #565656 !important;
}

.darkConsignmentMap .leaflet-interactive {
  stroke: white !important;
}
