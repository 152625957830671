.thumbnailList {
  margin: 0px;
  margin-left: 30px;
}

.thumbnailImage {
  padding-top: 10px;
  background-color: rgba(180, 180, 180, 0.5);
  width: 140px;
}

.imageTypeSelector:hover {
  background-color: black;
}

.paperWorkButton {
  cursor: pointer;
  z-index: 20 !important;
  display: none !important;
}
.dropFilesBox {
  cursor: pointer;
}
.dropFilesBox:hover .addFilesIcon {
  /* color: #0e78c4 !important; */
  transition: all 0.3s ease;
}

.thumbnailImage:hover > .paperWorkButton {
  background-color: #0e78c4 !important;
  display: flex !important;
  transition: all 0.3s ease;
}

.imageListItemBarDeleteButton {
  background: rgba(214, 14, 14, 0) !important;
}

.imageListItemBarDeleteButton:hover {
  color: red !important ;
}

.documentTypeSelectBox {
  cursor: pointer;
  border: 1px solid #0e78c400;
}

.documentTypeSelectBox:hover {
  border: 1px dashed #0e78c4;
}

.thumbnailPreview {
  position: relative;
  bottom: 60px;
  text-align: center;
  width: 40px;
  margin: auto;
}

.thumbnailPreview .viewIcon {
  font-size: 35px !important;
  display: none !important;
}

.thumbnailImage:hover .viewIcon {
  display: flex !important;
  transition: all 0.2s ease;
}

.listFileName {
  font-size: 0.8rem !important;
  font-weight: 600 !important;
}

.imageListItem {
  height: 100% !important;
}

.preview_image {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.modal_image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  z-index: 999 !important;
  position: relative;
}
