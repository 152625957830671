/* prettier-ignore */
:root {
    --myPortal: #21D0B2;
    --myPortalRGBA: rgba(33, 208, 178, 0.1);
    --flow: #009fe3;
    --flowRGBA: rgba(0, 159, 227, 0.1);
    --fusion: #C04CFD;
    --fusionRGBA: rgba(192, 76, 253, 0.2);
    --default: #32cd32;
    --defaultRGBA: rgba(50, 205, 50, 0.2);
    --border-color: #62667960;
    --height: 40px;
    --border-radius: 4px;
    --padding: 10px;
}

/* Colour for flow, but also as default if no product level match */
div.ruleGroup {
  background-color: var(--flowRGBA);
  border-style: none;
}

div.builderWrapper[data-productlevel='3'] div.ruleGroup {
  background-color: var(--myPortalRGBA);
  border-style: none;
}

div.builderWrapper[data-productlevel='1'] div.ruleGroup {
  background-color: var(--fusionRGBA);
  border-style: none;
}

div.ruleGroup:first-of-type:has(> div.ruleGroup-body:empty) {
  background-color: transparent;
}

div.ruleGroup-header {
  gap: 10px;
}

select.ruleGroup-combinators {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  width: 156px;
  height: var(--height);
  padding: var(--padding);
  cursor: pointer;
}

button.ruleGroup-addRule,
button.ruleGroup-addGroup,
button.ruleGroup-remove {
  background-color: #fff;
  color: #626679;
  border: 1px solid var(--border-color);
  outline: none;
  border-radius: var(--border-radius);
  height: var(--height);
  width: 92px;
  color: #000;
  cursor: pointer;
}

button.ruleGroup-remove {
  width: 46px;
  font-size: 1.2rem;
  color: #626679;
}

button.ruleGroup-addRule:hover,
button.ruleGroup-addGroup:hover,
button.ruleGroup-remove:hover {
  color: #fff;
}

div.builderWrapper[data-productlevel='1'] button.ruleGroup-addRule:hover,
div.builderWrapper[data-productlevel='1'] button.ruleGroup-addGroup:hover,
div.builderWrapper[data-productlevel='1'] button.ruleGroup-remove:hover {
  background-color: var(--fusion);
  border-color: var(--fusion);
}

div.builderWrapper[data-productlevel='2'] button.ruleGroup-addRule:hover,
div.builderWrapper[data-productlevel='2'] button.ruleGroup-addGroup:hover,
div.builderWrapper[data-productlevel='2'] button.ruleGroup-remove:hover {
  background-color: var(--flow);
  border-color: var(--flow);
}

div.builderWrapper[data-productlevel='3'] button.ruleGroup-addRule:hover,
div.builderWrapper[data-productlevel='3'] button.ruleGroup-addGroup:hover,
div.builderWrapper[data-productlevel='3'] button.ruleGroup-remove:hover {
  background-color: var(--myPortal);
  border-color: var(--myPortal);
}

/* When there is a value input */
div.rule:has(> input.rule-value) {
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
}

div.rule:has(> input.rule-value) > select.rule-fields {
  flex-basis: 49%;
  height: var(--height);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  padding: var(--padding);
}

div.rule:has(> input.rule-value) > select.rule-operators {
  flex-basis: 49%;
  flex-shrink: 0;
  height: var(--height);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  padding: var(--padding);
}

div.rule:has(> input.rule-value) > input.rule-value {
  flex-basis: 90%;
  flex-shrink: 0;
  height: var(--height);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  padding: var(--padding);
}

div.rule:has(> input.rule-value) > button.rule-remove {
  flex: 1;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: #fff;
  cursor: pointer;
  font-size: 1.571rem;
  color: #626679;
  height: var(--height);
}

/* No value or between inputs */
div.rule:not(:has(> input.rule-value)) {
  justify-content: flex-start;
  position: relative;
}

div.rule:not(:has(> input.rule-value)) > select.rule-fields {
  flex: 1;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: var(--padding);
}

div.rule:not(:has(> input.rule-value)) > select.rule-operators {
  flex: 1;
  flex-shrink: 0;
  height: var(--height);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  padding: var(--padding);
}

div.rule:not(:has(> input.rule-value)) > button.rule-remove {
  flex-basis: 8.5%;
  height: var(--height);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  background-color: #fff;
  cursor: pointer;
  font-size: 1.571rem;
  color: #626679;
}

/* Checking between */
div.rule:has(> span.rule-value) {
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
}

div.rule:has(> span.rule-value) > select.rule-fields {
  flex-basis: 49%;
  height: var(--height);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  padding: var(--padding);
}

div.rule:has(> span.rule-value) > select.rule-operators {
  flex-basis: 49%;
  flex-shrink: 0;
  height: var(--height);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  padding: var(--padding);
}

div.rule:has(> span.rule-value) > span.rule-value {
  flex-basis: 90%;
  flex-shrink: 0;
  height: var(--height);
  padding: 0px;
}

div.rule:has(> span.rule-value) > span.rule-value > input {
  height: 100%;
  flex: 1;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
}

div.rule:has(> span.rule-value) > button.rule-remove {
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-size: 1.571rem;
  color: #626679;
  border: 1px solid var(--border-color);
}

button.rule-remove:hover {
  background-color: var(--default);
  color: #fff;
}

div.builderWrapper[data-productlevel='1'] button.rule-remove:hover {
  background-color: var(--fusion);
  border-color: var(--fusion);
  color: #fff;
}

div.builderWrapper[data-productlevel='2'] button.rule-remove:hover {
  background-color: var(--flow);
  border-color: var(--flow);
  color: #fff;
}

div.builderWrapper[data-productlevel='3'] button.rule-remove:hover {
  background-color: var(--myPortal);
  border-color: var(--myPortal);
  color: #fff;
}
