.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: 150px !important;
}


  .swal-confirm-button {
    min-width: 90px;
    padding: 7px 19px !important;
    border-radius: 5px !important ;
    background-color: #626679 !important;
    font-size: 14px !important;
  }


  .swal-deny-button {
    min-width: 90px;
    padding: 7px 19px !important;
    border-radius: 5px !important ;
    background-color:#fff  !important;
    color:#626679 !important;
    font-size: 14px !important;
    border: 1px solid #626679 !important;
  }

  .swal-deny-button:hover {
    background-color: #626679 !important;
    color:#fff !important;
  }

  .swal2-footer {
    padding-top: 5px !important;
    height: 15px !important;
    font-size: 0.9rem !important;
  }

  .swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 1px #626679 !important;
}

.swal2-styled.swal2-deny:focus {
  box-shadow: 0 0 0 1px #626679 !important;
}
